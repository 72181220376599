import React from "react";
import Layout from "../../components/layout";
import Hero from "../../components/Hero";
import { ContentProvider } from "../../../contexts/ContentContext";
import JobContent from "./JobContent";

const Job = ({ pageContext }) => {
  const {
    job: {
      id,
      databaseId,
      featuredImage,
      FeaturedImages,
      nodeType,
      HeroSection,
      JobPosting,
      PageCptFields,
      PreFooter,
      seo,
      slug,
      status,
      title,
      uri,
    },
  } = pageContext;
  return (
    <ContentProvider
      value={{
        id,
        databaseId,
        featuredImage,
        FeaturedImages,
        nodeType,
        HeroSection,
        JobPosting,
        PageCptFields,
        PreFooter,
        seo,
        slug,
        status,
        title,
        uri,
      }}
    >
      <Layout>
        <Hero
          data={{
            ...HeroSection,
            ...FeaturedImages,
            featuredImage: featuredImage?.node,
          }}
        />
        <JobContent title={pageContext.job.title} data={JobPosting} />
      </Layout>
    </ContentProvider>
  );
};

export default Job;
