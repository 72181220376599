import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";

const DiversityStatementContainer = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 23.275862069%;
  }
`;

const DiversityStatementTitle = styled.h4`
  font-size: 2.4rem;
  line-height: 38px;
  color: ${props => props.theme.colors.darkerPurple};
  margin-bottom: 1.5rem;
`;

const DiversityStatementContent = styled.p`
  color: ${props => props.theme.colors.lighterPurple};
  font-size: 1.6rem !important;
  line-height: 27px !important;
  margin-bottom: 3.5rem;
`;

const DiversityStatement = () => {
  const {
    wp: {
      generalContent: {
        GeneralContent: { diversityStatement },
      },
    },
  } = useStaticQuery(diversityStatementQuery);
  return (
    <DiversityStatementContainer className={`contentContainer`}>
      {diversityStatement.title && (
        <DiversityStatementTitle>
          {diversityStatement.title}
        </DiversityStatementTitle>
      )}
      {diversityStatement.content && (
        <DiversityStatementContent>
          {diversityStatement.content}
        </DiversityStatementContent>
      )}
    </DiversityStatementContainer>
  );
};

const diversityStatementQuery = graphql`
  query {
    wp {
      generalContent {
        GeneralContent {
          diversityStatement {
            content
            fieldGroupName
            title
          }
        }
      }
    }
  }
`;

export default DiversityStatement;
