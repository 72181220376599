import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const LEFT = "left";
const CENTER = "center";
const RIGHT = "right";

const setUnderlineAlignment = alignment => {
  switch (alignment) {
    case LEFT:
      return `left: 0;`;
    case CENTER:
      return `left: 50%; transform: translateX(-50%);`;
    case RIGHT:
      return `right: 0;`;
    default:
      return `left: 50%; transform: translateX(-50%);`;
  }
};
const Descriptor = props => {
  const DescriptorComponent = styled.h3`
    padding-top: 1.85rem;
    color: ${props.color} !important;
    font-size: 1.6rem !important;
    line-height: 23px;
    font-family: ${props => props.theme.fonts.condensedMedium};
    text-transform: uppercase;
    letter-spacing: 2.88px;
    text-align: left;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 25%; */
      padding-top: 1.25rem;
      text-align: ${props.alignment};
    }
    span {
      display: inline-block;
      padding-bottom: 2rem;
      position: relative;
      text-align: ${props.textAlign};
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 88px;
        height: 2px;
        left: 0;
        background: ${props.color};
        @media (min-width: ${props => props.theme.breakpoints.md}) {
          ${setUnderlineAlignment(props.alignment)}
        }
      }
    }
  `;
  return (
    <DescriptorComponent>
      {props.title && (
        <span dangerouslySetInnerHTML={{ __html: props.title }} />
      )}
    </DescriptorComponent>
  );
};

Descriptor.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  alignment: PropTypes.oneOf(["left", "center", "right"]),
};

export default Descriptor;
