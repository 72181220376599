import React from "react";
import styled from "@emotion/styled";
import CtaButton from "../../components/CtaButton";
import { useTheme } from "@emotion/react";

const JobPrefooterContainer = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 23.275862069%;
  }
`;

const JobPrefooterTitle = styled.h3`
  font-size: 3.1rem;
  line-height: 38px;
  margin-bottom: 3rem;
  color: ${props => props.theme.colors.darkGold};
`;

const JobPrefooterContent = styled.div`
  p {
    font-size: 1.8rem;
    line-height: 29px;
    margin-bottom: 3.5rem;
  }
`;

const JobPrefooter = ({ data }) => {
  const theme = useTheme();
  return (
    <JobPrefooterContainer className={`contentContainer`}>
      {data.title && <JobPrefooterTitle>{data.title}</JobPrefooterTitle>}
      {data.content && (
        <JobPrefooterContent
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      )}
      {data.cta && (
        <CtaButton
          style={{ color: theme.colors.offWhite }}
          type="solid"
          color="darkRed"
          link={data.cta}
        />
      )}
    </JobPrefooterContainer>
  );
};

export default JobPrefooter;
