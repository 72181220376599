import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor/index";
import Image from "../../components/image";
import DiversityStatement from "./DiversityStatement";
import JobPrefooter from "./JobPrefooter";

const JobComponent = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};

  .introWrapper {
    padding: 5rem 1rem 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 8rem 0 0;
      width: 62.5%;
      margin-left: 25.78125%;
    }
    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      margin-left: 25%;
    }
  }

  .job-title {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 4rem;
    line-height: 50px;
    color: ${props => props.theme.colors.darkPink};
    margin-bottom: 3rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 6.5rem;
      line-height: 80px;
      margin-bottom: 4rem;
    }
  }

  .subtitle {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 3rem;
    width: 85%;
  }

  .sectionWrapper {
    padding: 1rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 1rem 6rem;
      display: flex;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }

  .contentContainer {
    margin-top: 1rem;
    font-family: ${props => props.theme.fonts.regular};
    color: ${props => props.theme.colors.offBlack};
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 62.5%;
    }

    h2 {
      font-size: 3.1rem;
      margin-bottom: 3rem;
      line-height: 38px;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-family: ${props => props.theme.fonts.medium};
        font-weight: 500;
        font-size: 4.8rem;
        line-height: 60px;
      }
    }

    h3 {
      font-size: 3.1rem;
      line-height: 38px;
      margin-bottom: 3rem;
      color: ${props => props.theme.colors.darkGold};
    }

    h4 {
      font-size: 2.4rem;
      line-height: 38px;
      margin-bottom: 1.5rem;
    }

    ul {
      margin-bottom: 4rem;
    }

    li {
      font-size: 1.8rem;
      line-height: 32px;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 29px;
      margin-bottom: 3.5rem;
    }

    a {
      color: ${props => props.theme.colors.lighterPurple};
      text-decoration: underline;
    }
  }
`;

const JobContent = ({ data, title }) => {
  return (
    <JobComponent>
      <div className="introWrapper">
        <h1 className="job-title">{title}</h1>
        <p className="subtitle">{data.intro}</p>
      </div>
      {data.sections.map((section, index) => (
        <div key={index} className="sectionWrapper">
          <div className="leftWrapper">
            <Descriptor
              title={section.descriptor}
              color={section.descriptorColor}
              alignment={section.underlineAlignment}
            />
            {section.illustration && (
              <Image
                css={css`
                  width: 5.45rem;
                  height: 5.45rem;
                  margin: 3rem 0;
                `}
                image={section.illustration}
              />
            )}
          </div>
          <div
            className="contentContainer"
            dangerouslySetInnerHTML={{ __html: section.content }}
          ></div>
        </div>
      ))}
      <div className="sectionWrapper">
        <JobPrefooter data={data.prefooter} />
      </div>
      <div className="sectionWrapper">
        <DiversityStatement />
      </div>
    </JobComponent>
  );
};

JobContent.propTypes = {
  title: PropTypes.string.isRequired, // Job Title
  data: PropTypes.object,
};

export default JobContent;
